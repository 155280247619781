import {
  addAttributeValue,
  createAttributeGroup,
  useAttributeGroupsList,
} from '@entities/attributes'
import { queryClient } from '@shared/api'
import { MESSAGE } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { type AddAttributeGroupFormInputs } from './types'

export const useAddAttributeGroup = () => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog } = useDialog()
  const methods = useForm<AddAttributeGroupFormInputs>()

  const attributesGroups = useAttributeGroupsList()
  const newOrder = attributesGroups.data && attributesGroups.data.length + 1

  if (!methods.formState.dirtyFields.order && newOrder) {
    methods.setValue('order', newOrder)
  }

  const closeModal = () => {
    handleCloseDialog()
    methods.reset()
  }

  const mutation = useMutation({
    mutationFn: async ({ name, order }: AddAttributeGroupFormInputs) => {
      const newGroup = await createAttributeGroup({
        ...(order && { order }),
      })

      const attributeWithName = newGroup.attributes.find((attr) => {
        return attr.is_name
      })

      if (!attributeWithName?.id) {
        return null
      }

      const newGroupWithName = await addAttributeValue({
        object_id: newGroup.id,
        value: name,
        attribute: attributeWithName?.id,
      })

      return newGroupWithName
    },
    onError: () => {
      methods.setError('root', {
        type: 'manual',
        message: MESSAGE.SERVER_ERROR,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['attribute-group-list'] })
      closeModal()
    },
  })

  const addGroup = methods.handleSubmit((data) => {
    mutation.mutate(data)
  })

  return {
    isOpen,
    showModal,
    closeModal,
    methods,
    addGroup,
    isLoading: mutation.isPending,
  }
}
