import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type API_ATTRIBUTE_MODEL, type API_QUERY_KEY } from '../constans'
import { type ApiAttribute } from '../types'
import { getAttributeList } from './get-attribute-list'

export const useAttributeListApi = (
  groupType: API_ATTRIBUTE_MODEL,
  queryKey: API_QUERY_KEY[],
): UseQueryResult<ApiAttribute[]> => {
  const getFilteredAttributeList = async (
    groupType: API_ATTRIBUTE_MODEL,
  ): Promise<ApiAttribute[]> => {
    const attributes = await getAttributeList()

    return attributes.filter((attribute) => {
      return attribute.model === groupType
    })
  }

  return useQuery<ApiAttribute[]>({
    queryKey: queryKey,
    queryFn: () => {
      return getFilteredAttributeList(groupType)
    },
  })
}
