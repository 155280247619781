import { addAttributesList, useAttributeGroupsList } from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { useSkuApi } from '@entities/catalog'
import { useParams } from 'react-router-dom'

export const usePrintData = () => {
  const { id } = useParams()
  const sku = useSkuApi(id)

  const attributesGroups = useAttributeGroupsList()

  if (attributesGroups.isSuccess && sku.isSuccess) {
    return addAttributesList({
      groups: attributesGroups.data as AttributeGroup[],
      attributes: sku.data.attributes,
    })
  }

  return null
}
