import { EuiHeader, EuiHeaderLink, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { AddAttributeGroup } from '@features/attributes'
import { noShadow } from '@shared/ui/styles'

export const AttributeGroupsHeader = () => {
  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection>{/* TODO: Добавить поиск по группам */}</EuiHeaderSection>

      <EuiHeaderSectionItem>
        <EuiHeaderLink iconType="trash" color="primary" iconSide="right" disabled>
          Удалить выбранные
        </EuiHeaderLink>
        <AddAttributeGroup />
      </EuiHeaderSectionItem>
    </EuiHeader>
  )
}
