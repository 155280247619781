import { EuiFlexGroup, EuiFlexItem, EuiPageSidebar, EuiPanel, EuiSpacer } from '@elastic/eui'
import { useSkuApi, useSkuTitle } from '@entities/catalog'
import { useSettingsStore } from '@entities/settings'
import { SkuDraggableList, SkuSidebarToggle, SkuToolbar } from '@features/catalog'
import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { ELEMENTS_M_WIDTH } from '@shared/config'
import { Image } from '@shared/ui/image'
import { useStyles } from '@shared/ui/styles'
import { useParams } from 'react-router-dom'

import { getStyles } from './styles'

export const SkuSidebar: ReactFc = () => {
  const { id } = useParams()
  const { data } = useSkuApi(id)

  const styles = useStyles(getStyles)
  const isSkuSidebarOpen = useSettingsStore.use.isSkuSidebarOpen()
  const minWidth = isSkuSidebarOpen ? ELEMENTS_M_WIDTH : 58

  const name = useSkuTitle()
  const preview = extractAttribute(data, ATTRIBUTE_FLAGS.PREVIEW) || ''

  if (!isSkuSidebarOpen) {
    return (
      <EuiPageSidebar minWidth={minWidth} sticky css={styles.sidebar}>
        <EuiPanel hasShadow={false} paddingSize="m">
          <SkuSidebarToggle />
        </EuiPanel>
      </EuiPageSidebar>
    )
  }

  return (
    <EuiPageSidebar minWidth={minWidth} sticky css={styles.sidebar}>
      <EuiPanel color="subdued">
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <SkuToolbar />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <SkuSidebarToggle />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="s" />

        <EuiFlexGroup justifyContent="spaceAround">
          <EuiFlexItem>
            <Image width={'100%'} height={ELEMENTS_M_WIDTH} src={preview} alt={name} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>

      <SkuDraggableList />
    </EuiPageSidebar>
  )
}
