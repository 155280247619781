import { EMPTY_ARRAY } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { useEffect } from 'react'

export const useCategoryFilterCheckbox = (value: number, childNodes: number[] = EMPTY_ARRAY) => {
  const { hasParamValue, addParamValue, removeParamValue, toggleParamValue } = useRouterParams()

  // Проверяем, что все дочернии категории сейчас выделены
  const isAllchildNodesChecked = () => {
    if (childNodes.length > 0) {
      return childNodes.every((childNodesId) => {
        return hasParamValue('category', childNodesId)
      })
    }

    return false
  }

  // Проверяем, что выделены некоторые из дочерних категорий
  const isIndeterminate = () => {
    if (childNodes.length > 0) {
      return childNodes.some((childNodesId) => {
        return hasParamValue('category', childNodesId) && !isAllchildNodesChecked()
      })
    }

    return false
  }

  // Проверяем, что выделена текущая категория
  const isChecked = hasParamValue('category', value)

  const handleToggle = () => {
    toggleParamValue('category', value)

    // Если выделена текущая категория или частично выделена, то при клике убираем все подкатегории
    if (!isChecked && !isIndeterminate()) {
      childNodes?.forEach((value) => {
        return addParamValue('category', value)
      })
    } else {
      childNodes?.forEach((value) => {
        return removeParamValue('category', value)
      })
    }
  }

  // Защита React route от изменения состояния до того, как он сделал рендер
  useEffect(() => {
    if (isAllchildNodesChecked()) {
      addParamValue('category', value)
    }

    if (isChecked && !isAllchildNodesChecked() && childNodes.length > 0) {
      removeParamValue('category', value)
    }
  }, [childNodes, isChecked, isIndeterminate])

  return {
    isIndeterminate,
    isChecked,
    handleToggle,
  }
}
