import { EuiBasicTable, EuiPageSection } from '@elastic/eui'
import { attributeGroupsColumns, useAttributeGroupsList } from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { EMPTY_ARRAY, MESSAGE } from '@shared/config'
import { useSortTable } from '@shared/hooks'
import { useNavigate } from 'react-router-dom'

export const AttributeGroupsTable: ReactFc = () => {
  const { sorting, sortList, onTableChange } = useSortTable('order')
  const navigate = useNavigate()

  const { isLoading, data = EMPTY_ARRAY } = useAttributeGroupsList()

  const getRowProps = ({ href }: AttributeGroup) => {
    return {
      onClick: () => {
        navigate(href)
      },
    }
  }

  return (
    <EuiPageSection paddingSize="none">
      <EuiBasicTable
        columns={attributeGroupsColumns}
        items={sortList(data)}
        loading={isLoading}
        noItemsMessage={(isLoading && <>{MESSAGE.LOADING}</>) || <>{MESSAGE.EMPTY}</>}
        sorting={sorting}
        onChange={onTableChange}
        rowProps={getRowProps}
      />
    </EuiPageSection>
  )
}
