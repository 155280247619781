import { Comparators, type Criteria, type EuiTableSortingType } from '@elastic/eui'
import { useState } from 'react'

export const useSortTable = <T extends object>(defaultSort = '') => {
  const [sortField, setSortField] = useState<keyof T | string>(defaultSort)
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')

  const sorting: EuiTableSortingType<T> = {
    sort: {
      field: sortField as keyof T,
      direction: sortDirection,
    },
    enableAllColumns: true,
  }

  const sortList = (list: T[]) => {
    if (sortField) {
      return list
        .slice(0)
        .sort(Comparators.property(sortField as string, Comparators.default(sortDirection)))
    } else {
      return list
    }
  }

  const onTableChange = ({ sort }: Criteria<T>) => {
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort
      setSortField(sortField)
      setSortDirection(sortDirection)
    }
  }

  return {
    sorting,
    sortList,
    onTableChange,
  }
}
