import {
  EuiFieldSearch,
  EuiHeader,
  EuiHeaderLink,
  EuiHeaderLinks,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiIcon,
} from '@elastic/eui'
import { noShadow } from '@shared/ui/styles'

import { Breadcrumbs } from './breadcrumbs'
import { UserHeaderMenu } from './user-header-menu'

export const Header = () => {
  return (
    // TODO: Это пример, надо всё пересобирать
    <EuiHeader css={noShadow}>
      <Breadcrumbs />

      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <EuiHeaderLinks>
            <EuiHeaderLink iconType="spaces" color="primary">
              Публичный каталог
            </EuiHeaderLink>
            <EuiFieldSearch placeholder="Поиск" compressed size={34} />
            <EuiHeaderLink iconType="documents" color="primary">
              Документация
            </EuiHeaderLink>
          </EuiHeaderLinks>
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          <EuiHeaderLinks>
            <EuiHeaderSectionItemButton notification={12}>
              <EuiIcon type="bell" size="l" />
            </EuiHeaderSectionItemButton>
            <EuiHeaderLink iconType="documentation" color="primary">
              Последние изменения
            </EuiHeaderLink>

            <UserHeaderMenu />
          </EuiHeaderLinks>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  )
}
