import { type EuiThemeComputed } from '@elastic/eui'
import { css } from '@emotion/react'
import { ELEMENTS_S_WIDTH } from '@shared/config'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = (euiTheme: EuiThemeComputed) => {
  return {
    menu: css`
      min-width: ${ELEMENTS_S_WIDTH}px;
    `,
    notificationsCount: css`
      color: ${euiTheme.colors.primary};
    `,
  }
}
