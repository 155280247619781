import { API_OBJECT_GROUP_LIST } from '../constans'
import { buildRequestApi } from '../lib'
import { type ApiObjectGroup } from '../types'

export const getObjectGroup = (id: string) => {
  return buildRequestApi<ApiObjectGroup>(
    `${API_OBJECT_GROUP_LIST}${id}/`,
    'Ошибка загрузки API Object Group',
  )()
}
