import {
  API_OBJECT_GROUP,
  API_OBJECT_GROUP_LIST,
  type ApiObjectGroup,
  buildRequestApi,
  HTTP_METHOD,
} from '@shared/api'

import { type CreateAttributeGroupArgs } from './types'

export const createAttributeGroup = buildRequestApi<ApiObjectGroup, CreateAttributeGroupArgs>(
  API_OBJECT_GROUP_LIST,
  'Ошибка при создании группы атрибутов',
  {
    method: HTTP_METHOD.POST,
    body: {
      type: API_OBJECT_GROUP.ATTRIBUTE,
    },
  },
)
