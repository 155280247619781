import { useTitle } from '@shared/hooks'
import { useParams } from 'react-router-dom'

import { useSkuApi } from '../api'

export const useSkuTitle = () => {
  const { id } = useParams()
  const sku = useSkuApi(id)

  return useTitle(sku)
}
