import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'

export const ErrorPage = () => {
  useMeta({ title: PAGE_TITLES.ERROR.title })

  return (
    <div id="error-page">
      <h1>Ошибка!</h1>
      <p>Страница не найдена</p>
    </div>
  )
}
