import { EuiForm, EuiPanel, EuiSpacer } from '@elastic/eui'
import { CatalogSelect, CategoryFilter, CategorySearch } from '@features/catalog'
import React from 'react'

const CatalogSidebarForm: ReactFc = () => {
  return (
    <EuiPanel hasShadow={false}>
      <EuiForm fullWidth>
        <CatalogSelect />
        <EuiSpacer />

        <CategorySearch />
        <EuiSpacer />

        <CategoryFilter />
      </EuiForm>
    </EuiPanel>
  )
}

const CatalogSidebarFormMemo = React.memo(CatalogSidebarForm) as typeof CatalogSidebarForm

export { CatalogSidebarFormMemo as CatalogSidebarForm }
