import {
  API_ATTRIBUTE_MODEL,
  API_OBJECT_GROUP,
  API_QUERY_KEY,
  ATTRIBUTE_FLAGS,
  oldExtractAttribute,
  useAttributeListApi,
  useObjectGroupListApi,
} from '@shared/api'
import { EMPTY_ARRAY, MESSAGE, ROUTES } from '@shared/config'
import { createNewObject } from '@shared/lib'

import { type AttributeGroup } from '../types'

export const useAttributeGroupsList = () => {
  const attributeObjectGroups = useObjectGroupListApi(API_OBJECT_GROUP.ATTRIBUTE, [
    API_QUERY_KEY.ATTRIBUTE_GROUP_LIST,
  ])

  const attributeList = useAttributeListApi(API_ATTRIBUTE_MODEL.SKU, [
    API_QUERY_KEY.ATTRIBUTE_SKU_LIST,
  ])

  if (attributeObjectGroups.data && attributeList.data) {
    const attributeGroupsMap: Record<number, AttributeGroup> = createNewObject()

    // Извлекаем основную информацию о группе атрибутов
    attributeObjectGroups.data.forEach((group) => {
      attributeGroupsMap[group.id] = {
        ...group,
        href: ROUTES.SETTINGS.ATTRIBUTE_GROUPS.GROUP.buildPath({ id: group.id }),
        name: oldExtractAttribute(group, ATTRIBUTE_FLAGS.NAME) || MESSAGE.EMPTY_NAME,
        count: 0,
        attributesIds: EMPTY_ARRAY,
        attributes: EMPTY_ARRAY,
      }
    })

    // Считаем количество атрибутов в группе
    attributeList.data.forEach((attribute) => {
      if (attribute.object_group) {
        attributeGroupsMap[attribute.object_group].count += 1

        attributeGroupsMap[attribute.object_group].attributesIds = attributeGroupsMap[
          attribute.object_group
        ].attributesIds.concat(attribute.id)
      }
    })

    const data = Object.values(attributeGroupsMap)

    return {
      ...attributeObjectGroups,
      data,
    }
  }

  return {
    ...attributeObjectGroups,
  }
}
