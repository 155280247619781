import { EuiPageSidebar, EuiResizableButton } from '@elastic/eui'
import { useSettingsStore } from '@entities/settings'
import { useResizableButton } from '@shared/hooks'
import { useStyles } from '@shared/ui/styles'

import { CatalogSidebarForm } from './catalog-sidebar-form'
import { getStyles } from './styles'

export const CatalogSidebar: ReactFc = () => {
  const styles = useStyles(getStyles)
  const { width, onMouseDown, onKeyDown } = useResizableButton()

  const isCatalogSidebarOpen = useSettingsStore.use.isCatalogSidebarOpen()
  const minWidth = isCatalogSidebarOpen ? width : 0

  return (
    <EuiPageSidebar minWidth={minWidth} sticky css={styles.sidebar}>
      <CatalogSidebarForm />

      <EuiResizableButton
        isHorizontal
        onMouseDown={onMouseDown}
        onTouchStart={onMouseDown}
        onKeyDown={onKeyDown}
        css={styles.resizeButton}
      />
    </EuiPageSidebar>
  )
}
