export const APP_NAME = 'SKU HUB'
// TODO: Ссылка на публичный каталог, скорее всего будет в ENV
export const APP_PUBLIC_URL = 'https://google.com/search?q='

export const SIMPLE_LAYOUT_CONTENT_WIDTH = 268
export const SIMPLE_LAYOUT_LOGO_WIDTH = 210

export const SIDEBAR_OPEN_MIN_WIDTH = 200
export const SIDEBAR_CLOSED_MIN_WIDTH = 59
export const SIDEBAR_OPEN_LOGO_WIDTH = 118
export const SIDEBAR_CLOSED_LOGO_WIDTH = 21

export const ELEMENTS_S_WIDTH = 300
export const ELEMENTS_M_WIDTH = 400

export const CATALOG_CARD_SKELETON_AMOUNT = 9
export const CATALOG_CARD_IMAGE_SIZE = 200
export const CATALOG_TABLE_IMAGE_SIZE = 50

export const EMPTY_ARRAY = []

export enum CATALOG_VIEW {
  CARDS = 'Сетка',
  TABLE = 'Таблица',
}

export enum MESSAGE {
  EDIT = 'Редактировать',
  EMPTY = 'Ничего не найдено',
  EMPTY_NAME = 'Без названия',
  LOADING = 'Загрузка…',
  BACK = 'Назад',
  SERVER_ERROR = 'Ошибка сервера, попробуйте ещё раз',
}
