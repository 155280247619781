import { type Sku } from '@entities/catalog/types'
import { useRouterParams } from '@shared/hooks'

export const useFilterSkuList = (skuList: Sku[]) => {
  const { getParamId, hasParam, getParamValues } = useRouterParams()

  return skuList
    .filter((item) => {
      const catalogId = getParamId()

      if (catalogId && item.object_groups) {
        return item.object_groups.includes(catalogId)
      }

      return item
    })
    .filter((item) => {
      if (hasParam('category')) {
        const categories = getParamValues('category')

        return item.categories.some((num) => {
          return categories.includes(num.toString())
        })
      }

      return item
    })
}
