import { EuiSkeletonLoading, EuiSkeletonText, EuiText, EuiTreeView } from '@elastic/eui'
import { useCategoryListApi } from '@entities/catalog'
import { MESSAGE } from '@shared/config'

import { useCategoryFilter } from '../model'
import { getCategoryFilterTree } from './category-filter-tree'
import { treeWrapper } from './styles'

export const CategoryFilter: ReactFc = () => {
  const { isLoading } = useCategoryListApi()
  const { treeItems, search } = useCategoryFilter()

  if (!isLoading && treeItems.length === 0) {
    if (search === '') {
      return (
        <EuiText size="s" color="subdued">
          Категории отсутствуют
        </EuiText>
      )
    }

    return (
      <EuiText size="s" color="subdued">
        {MESSAGE.EMPTY}
      </EuiText>
    )
  }

  return (
    <EuiSkeletonLoading
      isLoading={isLoading}
      contentAriaLabel="Фильтр по категориям"
      loadingContent={<EuiSkeletonText size="m" lines={6} />}
      css={treeWrapper}
      loadedContent={
        <EuiTreeView
          key={search} // Добавляем ключ для перерисовки компонента во время поиска
          showExpansionArrows
          items={getCategoryFilterTree(treeItems)}
        />
      }
    />
  )
}
