import { EuiLoadingSpinner, EuiText } from '@elastic/eui'
import { ROUTES } from '@shared/config'
import { deleteCookie } from '@shared/lib'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { postLogout } from '../api'

export const LogoutMessage: ReactFc = () => {
  const navigate = useNavigate()

  const { isLoading, isSuccess, isError, data } = useQuery({
    queryKey: ['auth'],
    queryFn: postLogout,
  })

  if (isSuccess && data.status === 200) {
    deleteCookie('csrftoken')
    navigate(ROUTES.LOGIN.path)
  }

  return (
    <>
      {isLoading && <EuiLoadingSpinner size="s" />}
      {isError && <EuiText size="xs">Ошибка сервера, перезагрузите страницу</EuiText>}
    </>
  )
}
