import { type CategoryFilterTree, type CategoryFilterTreeProps } from '../types'
import { CategoryFilterCheckbox } from './category-filter-checkbox'

export const getCategoryFilterTree = (items: CategoryFilterTreeProps[]): CategoryFilterTree[] => {
  return items.map((item) => {
    const { id, name, skus_count, childNodes, isExpanded, children } = item

    return {
      id: `category_${id}`,
      label: (
        <CategoryFilterCheckbox
          value={id}
          label={name}
          skus_count={skus_count}
          childNodes={childNodes}
        />
      ),
      isExpanded: isExpanded,
      children: children && getCategoryFilterTree(children),
    }
  })
}
