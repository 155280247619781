import { type ApiAttributeFlags, type ApiObjectWithAttributes } from '../types'

export const extractAttribute = (item: ApiObjectWithAttributes | undefined, key: string) => {
  const isFlagKey = key.startsWith('is_')

  if (!item) {
    return null
  }

  const attribute = item.attributes.find((attr) => {
    if (isFlagKey) {
      return attr[key as keyof ApiAttributeFlags] === true
    }

    return attr.slug === key
  })

  return attribute ? attribute.value : null
}

// TODO: Удалить, когда изменится API Opject group и Категорий
export const oldExtractAttribute = (item: ApiObjectWithAttributes, name: string) => {
  const attribute = item.attributes.find((attr) => {
    return Object.keys(attr).includes(name) || attr.name === name
  })

  if (attribute) {
    const attributeValue = item.attributes_values.find((value) => {
      return value.attribute === attribute.id
    })

    if (attributeValue) {
      return attributeValue.value
    }
  }

  return null
}
