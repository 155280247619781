import { API_ATTRIBUTE_VALUES, buildRequestApi, HTTP_METHOD } from '@shared/api'
import { type ApiAttributeValue, type AttributeValue } from '@shared/api/types'

export const addAttributeValue = buildRequestApi<ApiAttributeValue, AttributeValue>(
  API_ATTRIBUTE_VALUES,
  'Ошибка при добавлении значения атрибута',
  {
    method: HTTP_METHOD.POST,
  },
)
