import { EuiHeader, EuiHeaderSection } from '@elastic/eui'
import { CatalogSidebarToggle, CatalogViewToggle } from '@features/catalog'
import { noShadow, useStyles } from '@shared/ui/styles'

import { getStyles } from './styles'

export const CatalogHeader = () => {
  const styles = useStyles(getStyles)

  return (
    <EuiHeader css={[styles.header, noShadow]}>
      <EuiHeaderSection>
        <CatalogSidebarToggle />
      </EuiHeaderSection>

      <EuiHeaderSection>
        <CatalogViewToggle />
      </EuiHeaderSection>
    </EuiHeader>
  )
}
