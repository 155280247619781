import { EuiFlexItem, EuiPanel, EuiText } from '@elastic/eui'
import { SkuCard } from '@entities/catalog'
import { MESSAGE } from '@shared/config'

import { SkuCardSkeletonList } from './sku-card-skeleton-list'
import { grid } from './styles'
import { type CatalogCardsProps } from './types'

export const CatalogCards: ReactFc<CatalogCardsProps> = ({ skuList, isLoading }) => {
  if (!isLoading && skuList.length === 0) {
    return (
      <EuiPanel hasShadow={false} borderRadius="none" paddingSize="s" grow={false}>
        <EuiText size="s" textAlign="center">
          {MESSAGE.EMPTY}
        </EuiText>
      </EuiPanel>
    )
  }

  return (
    <EuiPanel paddingSize="none" color="transparent" grow={false} css={grid}>
      {isLoading && <SkuCardSkeletonList />}

      {skuList.map((item) => {
        return (
          <EuiFlexItem key={item.id}>
            <SkuCard {...item} />
          </EuiFlexItem>
        )
      })}
    </EuiPanel>
  )
}
