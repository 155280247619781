import { icon as apmTrace } from '@elastic/eui/es/components/icon/assets/apm_trace'
import { icon as apps } from '@elastic/eui/es/components/icon/assets/apps'
import { icon as arrowDown } from '@elastic/eui/es/components/icon/assets/arrow_down'
import { icon as arrowLeft } from '@elastic/eui/es/components/icon/assets/arrow_left'
import { icon as arrowRight } from '@elastic/eui/es/components/icon/assets/arrow_right'
import { icon as arrowUp } from '@elastic/eui/es/components/icon/assets/arrow_up'
import { icon as bell } from '@elastic/eui/es/components/icon/assets/bell'
import { icon as check } from '@elastic/eui/es/components/icon/assets/check'
import { icon as cross } from '@elastic/eui/es/components/icon/assets/cross'
import { icon as document } from '@elastic/eui/es/components/icon/assets/document'
import { icon as documentation } from '@elastic/eui/es/components/icon/assets/documentation'
import { icon as documents } from '@elastic/eui/es/components/icon/assets/documents'
import { icon as editorLink } from '@elastic/eui/es/components/icon/assets/editor_link'
import { icon as editorTable } from '@elastic/eui/es/components/icon/assets/editor_table'
import { icon as empty } from '@elastic/eui/es/components/icon/assets/empty'
import { icon as exit } from '@elastic/eui/es/components/icon/assets/exit'
import { icon as eye } from '@elastic/eui/es/components/icon/assets/eye'
import { icon as filter } from '@elastic/eui/es/components/icon/assets/filter'
import { icon as gear } from '@elastic/eui/es/components/icon/assets/gear'
import { icon as grab } from '@elastic/eui/es/components/icon/assets/grab'
import { icon as image } from '@elastic/eui/es/components/icon/assets/image'
import { icon as lock } from '@elastic/eui/es/components/icon/assets/lock'
import { icon as menuLeft } from '@elastic/eui/es/components/icon/assets/menuLeft'
import { icon as menuRight } from '@elastic/eui/es/components/icon/assets/menuRight'
import { icon as euipackage } from '@elastic/eui/es/components/icon/assets/package'
import { icon as pencil } from '@elastic/eui/es/components/icon/assets/pencil'
import { icon as plusInCircle } from '@elastic/eui/es/components/icon/assets/plus_in_circle'
import { icon as save } from '@elastic/eui/es/components/icon/assets/save'
import { icon as search } from '@elastic/eui/es/components/icon/assets/search'
import { icon as sortDown } from '@elastic/eui/es/components/icon/assets/sort_down'
import { icon as sortUp } from '@elastic/eui/es/components/icon/assets/sort_up'
import { icon as sortable } from '@elastic/eui/es/components/icon/assets/sortable'
import { icon as sortLeft } from '@elastic/eui/es/components/icon/assets/sortLeft'
import { icon as spaces } from '@elastic/eui/es/components/icon/assets/spaces'
import { icon as stopFilled } from '@elastic/eui/es/components/icon/assets/stop_filled'
import { icon as trash } from '@elastic/eui/es/components/icon/assets/trash'
import { icon as user } from '@elastic/eui/es/components/icon/assets/user'
import { icon as userAvatar } from '@elastic/eui/es/components/icon/assets/userAvatar'
import { icon as warning } from '@elastic/eui/es/components/icon/assets/warning'
import { appendIconComponentCache } from '@elastic/eui/es/components/icon/icon'

const cachedIcons = {
  apmTrace,
  apps,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  bell,
  check,
  cross,
  document,
  documentation,
  documents,
  editorLink,
  editorTable,
  exit,
  eye,
  empty,
  filter,
  gear,
  grab,
  image,
  lock,
  menuLeft,
  menuRight,
  package: euipackage,
  pencil,
  plusInCircle,
  save,
  search,
  sortDown,
  sortUp,
  sortable,
  sortLeft,
  spaces,
  stopFilled,
  trash,
  user,
  userAvatar,
  warning,
}

export const iconsInit = () => {
  appendIconComponentCache(cachedIcons)
}

// Icons src list: https://gist.github.com/unckleg/5476ebd940d1d473387bb082e8c2929c
