import { API_QUERY_KEY, useQuery } from '@shared/api'
import { ROUTES } from '@shared/config'

import { type ApiSkuItem, type Sku } from '../types'
import { getSkuList } from './get-sku-list'

export const useSkuListApi = () => {
  const skuList = useQuery<ApiSkuItem[]>([API_QUERY_KEY.SKU_LIST], getSkuList)

  const data: Sku[] = skuList.data?.map((sku): Sku => {
    const href = ROUTES.CATALOG.SKU.buildPath({ id: sku.id })

    const attributes = Object.fromEntries(
      sku.attributes.map((attribute) => {
        return [attribute.slug, attribute.value]
      }),
    )

    // @ts-ignore TODO: Сделать динамичные типы для атрибутов
    return {
      ...sku,
      ...attributes,
      href,
    }
  }) as Sku[]

  return {
    ...skuList,
    data,
  }
}
