import { EuiBasicTable, EuiPageSection } from '@elastic/eui'
import { attributeGroupColumns, useAttributeList } from '@entities/attributes'
import { EMPTY_ARRAY, MESSAGE } from '@shared/config'
import { useSortTable } from '@shared/hooks'
import { useParams } from 'react-router-dom'

export const AttributeGroupTable: ReactFc = () => {
  const { id } = useParams()
  const { sorting, sortList, onTableChange } = useSortTable()

  const { isLoading, data = EMPTY_ARRAY } = useAttributeList()

  const filteredList = data.filter((attribute) => {
    return attribute.object_group === Number(id)
  })

  return (
    <EuiPageSection paddingSize="none">
      <EuiBasicTable
        columns={attributeGroupColumns}
        items={sortList(filteredList)}
        loading={isLoading}
        noItemsMessage={(isLoading && <>{MESSAGE.LOADING}</>) || <>{MESSAGE.EMPTY}</>}
        sorting={sorting}
        onChange={onTableChange}
      />
    </EuiPageSection>
  )
}
