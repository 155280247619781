import { EuiButtonIcon, EuiHeaderSectionItem } from '@elastic/eui'
import { MESSAGE } from '@shared/config'
import { useGoBack } from '@shared/hooks'
import { Link } from 'react-router-dom'

import { type BackLinkProps } from './types'

export const BackLink: ReactFc<BackLinkProps> = ({ matches }) => {
  const currentMatch = matches[matches.length - 1]
  const disableBack = currentMatch?.handle?.disableBack
  const back = useGoBack()

  if (disableBack) {
    return null
  }

  return (
    <EuiHeaderSectionItem>
      <Link to={'..'} onClick={back}>
        <EuiButtonIcon iconType="sortLeft" color="text" size="xs" aria-label={MESSAGE.BACK} />
      </Link>
    </EuiHeaderSectionItem>
  )
}
