import { useSkuListApi } from '@entities/catalog'
import { isCatalogView, useFilterSkuList } from '@features/catalog'
import { CATALOG_VIEW, EMPTY_ARRAY } from '@shared/config'

import { CatalogCards } from './catalog-cards'
import { CatalogTable } from './catalog-table'

export const Catalog: ReactFc = () => {
  const { isLoading, data: skuList = EMPTY_ARRAY } = useSkuListApi()
  const filteredSkuList = useFilterSkuList(skuList)

  if (isCatalogView(CATALOG_VIEW.CARDS)) {
    return <CatalogCards skuList={filteredSkuList} isLoading={isLoading} />
  }

  return <CatalogTable skuList={filteredSkuList} isLoading={isLoading} />
}
