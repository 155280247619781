import { EuiIcon } from '@elastic/eui'

import { RenderLink } from '../ui'
import { createItem } from './create-item'

export const sidebarMenuItems = [
  {
    id: 'menu',
    name: 'МЕНЮ',
    items: [
      {
        id: 'all',
        name: 'Все товары',
        icon: <EuiIcon type="package" />,
        forceOpen: true,
        items: [createItem('Каталог', '/catalog')],
      },
      {
        id: 'dam',
        name: 'DAM',
        href: '/dam',
        icon: <EuiIcon type="documents" />,
        renderItem: RenderLink,
      },
      {
        id: 'settings',
        name: 'Настройки',
        icon: <EuiIcon type="gear" />,
        forceOpen: true,
        items: [
          createItem('Атрибуты', '/settings/attributes'),
          createItem('Группы атрибутов', '/settings/attribute-groups'),
          createItem('Списки значений', '/settings/value-lists'),
          createItem('Связанные товары', '/settings/related-products'),
          createItem('Инфомодели', '/settings/info-models'),
          createItem('Категоризация', '/settings/categorization'),
          createItem('Кластеризация', '/settings/clustering'),
          createItem('Таксономия', '/settings/taxonomy'),
          createItem('Шаблоны', '/settings/templates'),
          createItem('Правила', '/settings/rules'),
          createItem('Ед. измерений', '/settings/units'),
          createItem('Валюты и цены', '/settings/currencies-and-prices'),
        ],
      },
    ],
  },
]
