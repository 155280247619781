import { EuiFlexGroup, EuiFlexItem, EuiListGroup, EuiListGroupItem } from '@elastic/eui'
import { MESSAGE } from '@shared/config'
import { textRight } from '@shared/ui/styles'

import { list } from './styles'
import { type SkuAttributesListProps } from './types'

export const SkuAttributesList: ReactFc<SkuAttributesListProps> = ({ attributes }) => {
  return (
    <EuiListGroup flush gutterSize="none" css={list}>
      {attributes.map((attribute) => {
        return (
          <EuiListGroupItem
            key={attribute.id}
            label={
              <EuiFlexGroup gutterSize="s" justifyContent="spaceBetween">
                <EuiFlexItem>{attribute.name}</EuiFlexItem>
                <EuiFlexItem css={textRight}>{attribute.value}</EuiFlexItem>
              </EuiFlexGroup>
            }
            extraAction={{
              color: 'primary',
              iconType: 'pencil',
              alwaysShow: true,
              'aria-label': MESSAGE.EDIT,
            }}
            size="s"
            wrapText
          />
        )
      })}
    </EuiListGroup>
  )
}
