export const attributeGroupsColumns = [
  {
    field: 'order',
    name: 'Позиция',
    width: '150px',
  },
  {
    field: 'name',
    name: 'Название',
  },
  {
    field: 'count',
    name: 'Атрибутов в группе',
  },
]
