import { EuiForm, EuiHeaderLink } from '@elastic/eui'
import { FormError, NumberInput, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useAddAttributeGroup } from '../model'

export const AddAttributeGroup: ReactFc = () => {
  const { isOpen, showModal, closeModal, methods, addGroup, isLoading } = useAddAttributeGroup()

  return (
    <>
      <EuiHeaderLink iconType="plusInCircle" color="primary" iconSide="right" onClick={showModal}>
        Создать группу
      </EuiHeaderLink>

      <FormProvider {...methods}>
        <Modal
          title="Создание группы атрибутов"
          onClose={closeModal}
          onSubmit={addGroup}
          isOpen={isOpen}
          isLoading={isLoading}
        >
          <EuiForm component="form">
            <TextInput
              name="name"
              placeholder="Название группы"
              registerOptions={{ required: 'Введите название группы' }}
              inputProps={{
                isLoading: isLoading,
              }}
              isRequired
            />
            <NumberInput
              name="order"
              placeholder="Позиция"
              inputProps={{
                isLoading: isLoading,
              }}
            />

            <FormError />
          </EuiForm>
        </Modal>
      </FormProvider>
    </>
  )
}
