import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { Catalog, CatalogHeader, CatalogSidebar } from '@widgets/catalog'

export const CatalogPage = () => {
  useMeta({ title: PAGE_TITLES.CATALOG.title })

  return (
    <Layout
      header={<CatalogHeader />}
      sidebar={<CatalogSidebar />}
      pageBodyProps={{
        panelProps: {
          hasShadow: false,
        },
      }}
    >
      <Catalog />
    </Layout>
  )
}
