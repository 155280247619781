import {
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderLink,
  EuiIcon,
  EuiPopover,
  EuiPopoverFooter,
  EuiTextColor,
} from '@elastic/eui'
import { ROUTES } from '@shared/config'
import { useToggle } from '@shared/hooks'
import { useStyles } from '@shared/ui/styles'

import { getStyles } from './styles'

export const UserHeaderMenu: ReactFc = () => {
  const styles = useStyles(getStyles)
  const [isMenuOpen, toggleMenu] = useToggle()

  const userHeaderButton = (
    <EuiHeaderLink iconType="arrowDown" iconSide="right" color="text" onClick={toggleMenu}>
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiFlexItem>
          <EuiIcon type="user" />
        </EuiFlexItem>
        <EuiFlexItem>Профиль</EuiFlexItem>
      </EuiFlexGroup>
    </EuiHeaderLink>
  )

  const userLinksList = [
    <EuiContextMenuItem key="user" icon="user">
      Профиль
    </EuiContextMenuItem>,
    <EuiContextMenuItem key="bell" icon="bell">
      Уведомления <EuiTextColor css={styles.notificationsCount}>(+12)</EuiTextColor>
    </EuiContextMenuItem>,
    <EuiContextMenuItem key="eye" icon="eye">
      Измененные товары
    </EuiContextMenuItem>,
  ]

  return (
    <EuiPopover
      id="userContextMenuPopover"
      button={userHeaderButton}
      isOpen={isMenuOpen}
      closePopover={toggleMenu}
      panelPaddingSize="none"
      anchorPosition="downRight"
    >
      <EuiContextMenuPanel items={userLinksList} css={styles.menu} />
      <EuiPopoverFooter>
        <EuiContextMenuItem key="eye" icon="exit" href={ROUTES.LOGOUT.path}>
          Выход
        </EuiContextMenuItem>
      </EuiPopoverFooter>
    </EuiPopover>
  )
}
