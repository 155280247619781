import { useSkuApi } from '@entities/catalog'
import { APP_PUBLIC_URL } from '@shared/config'
import { useParams } from 'react-router-dom'

export const usePublicLink = () => {
  const { id } = useParams()
  const { data } = useSkuApi(id)

  const isPublic = data?.is_public || false

  const openPublicLink = () => {
    window.open(`${APP_PUBLIC_URL}${data?.id}`, '_blank')
  }

  return {
    isPublic,
    openPublicLink,
  }
}
