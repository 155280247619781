export const attributeGroupColumns = [
  {
    field: 'name',
    name: 'Наименование атрибута',
  },
  {
    field: 'attribute_type',
    name: 'Тип',
  },
]
