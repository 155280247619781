import { getCookie } from '@shared/lib'

export const API_BASE = '/backend/api/portal'

export const API_LOGIN = '/rest/login/'
export const API_LOGOUT = '/rest/logout/'

export const API_ATTRIBUTE_LIST = '/rest/attributes/'
export const API_ATTRIBUTE_VALUES = '/rest/attribute_values/'
export const API_ATTRIBUTE_TYPES_LIST = '/rest/attribute_types/'
export const API_OBJECT_GROUP_LIST = '/rest/object_groups/'
export const API_CATEGORY_LIST = '/rest/categories/'
export const API_SKU_LIST = '/rest/skus/'

export const DEFAULT_HEADERS: HeadersInit = {
  'Content-Type': 'application/json',
  'X-CSRFToken': getCookie('csrftoken') || '',
}

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum API_ATTRIBUTE_MODEL {
  SKU = 'SKU',
  CATEGORY = 'CATEGORY',
  OBJECT_GROUP = 'OBJECT_GROUP',
}

export enum API_OBJECT_GROUP {
  SKU = 1,
  CATEGORY = 2,
  FILE_UPLOAD = 3,
  ATTRIBUTE = 4,
}

export enum API_QUERY_KEY {
  ATTRIBUTE_SKU_LIST = 'attribute-sku-list',
  ATTRIBUTE_GROUP_LIST = 'attribute-group-list',
  CATALOG_LIST = 'catalogs-list',
  CATEGORY_LIST = 'categories-list',
  SKU_LIST = 'sku-list',
}

export enum ATTRIBUTE_FLAGS {
  SKU = 'is_sku',
  NAME = 'is_name',
  BRAND = 'is_brand',
  PREVIEW = 'is_preview',
}
