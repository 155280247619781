import { addAttributesList, useAttributeGroupsList } from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { useSkuApi } from '@entities/catalog'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { type DragEndParams, type UseSkuDraggableListReturn } from './types'

export const useSkuDraggableList = (): UseSkuDraggableListReturn => {
  const { id } = useParams()
  const attributesGroups = useAttributeGroupsList()
  const sku = useSkuApi(id)
  const [list, setList] = useState([] as AttributeGroup[])

  useEffect(() => {
    if (attributesGroups.isSuccess && sku.isSuccess) {
      const attributesGroupsList = addAttributesList({
        groups: attributesGroups.data as AttributeGroup[],
        // TODO: Добавить фильтр is_featured когда появится в API
        attributes: sku.data.attributes,
      })

      setList(attributesGroupsList)
    }
  }, [attributesGroups.isSuccess, sku.isSuccess])

  const onDragEnd = ({ source, destination }: DragEndParams) => {
    if (!source || !destination) return

    const reorderedItems = Array.from(list)
    const [removed] = reorderedItems.splice(source.index, 1)
    reorderedItems.splice(destination.index, 0, removed)

    setList(reorderedItems)
  }

  return [list, onDragEnd]
}
